import React from 'react';
import Logo from '@assets/medicare.png';
import SocialProofFeedST from '@components/staticSections/socialProofFeedST/socialProofFeedST';
import Layout from '../../components/layout';
import HeroCTA from '../../components/sections/heroCTA/heroCTA';
import Segment from '../../components/segment';
import Seo from '../../components/seo';
import ImageText from '../../components/sections/imageText/imageText';
import BgCardsTextST from '../../components/staticSections/bgCardsTextST/BgCardsTextST';
import OutcomeST from '../../components/staticSections/OutcomeST/OutcomeST';
import GetStartedST from '../../components/staticSections/GetStartedST/GetStartedST';
import TestimonialGridST from '../../components/staticSections/TestimonialGridST/TestimonialGridST';
import FaqST from '../../components/staticSections/FaqST/FaqST';
import CtaST from '../../components/staticSections/CtaST/CtaST';

const IndexPage = () => {
  return (
    <Layout
      lang="us"
      headerWitdh="header"
      topHeader
      text="Covered by Medicare"
      showFooter
      headerImage={
        <img className="logo--md margin-left--xs flex" alt="Medicare" src={Logo} />
      }
    >
      <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_US} />
      <Seo title="Medicare" language="en" />
      <HeroCTA
        dataTheme="ice-blue"
        imagedata4
        dataSection="hero_CTA"
        alt="Covered by Medicare"
        title="Treat joint and back pain from home"
        titleClassName="bold"
        text="Download the Joint Academy app to your phone and get started with a personalized exercise program today."
        textClassName=""
        // calldataGoal="Calendly Viewed"
        // callhref="https://calendly.com/d/k6dx-mdtf/joint-academy-physical-therapy"
        // callclassname="margin-top--md btn btn--primary btn--fullwidth btn--lg margin-bottom--xs btn--lgMob"
        // calltext="Get Started"
        downloadButton
        downloaddataGoal="Onboarding Viewed"
        downloadhref="https://app.adjust.com/gcv9i5g"
        downloadclassname="margin-top--md btn btn--fullwidth btn--lg btn--primary"
        downloadtext="GET STARTED"
        tagHeadline="Get a text to download the app"
        smsTag
        smsTagClassName="display--md"
        dataToken="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhZmZpbGlhdGVfbmFtZSI6IkNlbnRlbmUgTWVkaWNhcmUgQWR2YW50YWdlICgwMDEzWDAwMDAyY2tUaXBRQUUpIiwiaWF0IjoiMjAyMC0wNC0wMlQwMTo1NTo0My42ODNaIiwianRpIjoiYzI5Mjg3MWQtNmE0Zi00OTVkLTg0ODQtMGVkYzFhOGZlN2ZiIn0.7Wr3uJZ0xC9sY0k8dF9oKVO1BqK1LGzStyjQNI4aSmA"
      />
      <ImageText
        imgSize="col--lg-4"
        Filename="medicare.png"
        alt="Covered by Medicare"
        Position=""
        title="Covered by Medicare"
        text="Joint Academy is a covered benefit under most Medicare plans. Download the Joint Academy app to confirm your coverage, or contact support for further assistance."
        download
        downloaddataGoal="Onboarding Viewed"
        downloadhref="https://app.adjust.com/gcv9i5g"
        downloadclassname="btn btn--primary btn--md btn--lgMob"
        downloadtext="get started"
      />
      <GetStartedST
        lang="us_en"
        renderDownloadButton
        ctaButtonText="Get Started"
        ctaDataGoal="Onboarding Viewed"
        ctaHref="https://app.adjust.com/gcv9i5g"
      />
      <BgCardsTextST dataTheme="light" lang="us_en" />
      <SocialProofFeedST lang="us_en" />
      <TestimonialGridST lang="us_en" />
      <OutcomeST lang="us_en" />
      <FaqST lang="us_en" />
      <CtaST
        lang="us_en"
        ctaDataGoal="Calendly Viewed"
        ctaHref="https://calendly.com/d/k6dx-mdtf/joint-academy-physical-therapy"
        ctaButtonText="Schedule Call"
      />
    </Layout>
  );
};

export default IndexPage;
